import React from "react";
import { Link } from "gatsby";

import imgLB from "../../assets/image/logo-main-black.png";
import imgLW from "../../assets/image/logo-main-white.png";
import CompanyLogo from "../../assets/image/company-logo.png";
import fbIcon from "../../assets/image/fb.png";
import twitterIcon from "../../assets/image/twitter.png";
import linkedinIcon from "../../assets/image/linkedin.png";

const Footer = ({ className, ...rest }) => {
  return (
    <>
      <div
        className={`bg-primary pt-13 pt-lg-20 pb-7 dark-mode-texts position-relative ${className}`}
        {...rest}
      >
        <div className="container">
          <div className="row pb-lg-15">
            <div className="col-lg-4 col-md-5 col-md-3 col-xs-8">
              <div className="pr-xl-20 mb-11 mb-lg-0 mt-lg-5">
                <div className="brand-logo mb-5">
                  <Link to="/#">
                    <img
                      className="mx-auto mx-0 light-version-logo logo default-logo"
                      src={imgLB}
                      alt=""
                    />
                    <img
                      src={imgLW}
                      alt=""
                      className="dark-version-logo mx-auto mx-0 logo"
                    />
                  </Link>
                </div>
                <p className="font-size-5 mt-10 mb-0 text-white pr-sm-10 pr-md-0">
                  The comprehensive solution designed to streamline academic and
                  nonacademic operations within your higher education institute.
                </p>
                <div className="mt-15">
                  <Link
                    to="/contact#ask-question"
                    className="btn btn-white btn-sm rounded-5 text-black-100 font-size-3"
                  >
                    Ask Questions
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 offset-xl-1">
              <div className="row">
                {/* Single Widgets */}
                <div className="col-md-3 col-xs-6">
                  {/*<div className="mb-10 mb-lg-0">*/}
                  {/*  <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">*/}
                  {/*    Community*/}
                  {/*  </h4>*/}
                  {/*  <ul className="list-unstyled">*/}
                  {/*    <li className="mb-6">*/}
                  {/*      <Link*/}
                  {/*        to="/#"*/}
                  {/*        className="font-size-5 text-lily-white gr-hover-text-blue-3"*/}
                  {/*      >*/}
                  {/*        For Talents*/}
                  {/*      </Link>*/}
                  {/*    </li>*/}
                  {/*    <li className="mb-6">*/}
                  {/*      <Link*/}
                  {/*        to="/#"*/}
                  {/*        className="font-size-5 text-lily-white gr-hover-text-blue-3"*/}
                  {/*      >*/}
                  {/*        For Companies*/}
                  {/*      </Link>*/}
                  {/*    </li>*/}
                  {/*    <li className="mb-6">*/}
                  {/*      <Link*/}
                  {/*        to="/#"*/}
                  {/*        className="font-size-5 text-lily-white gr-hover-text-blue-3"*/}
                  {/*      >*/}
                  {/*        Facebook Group*/}
                  {/*      </Link>*/}
                  {/*    </li>*/}
                  {/*    <li className="mb-6">*/}
                  {/*      <Link*/}
                  {/*        to="/#"*/}
                  {/*        className="font-size-5 text-lily-white gr-hover-text-blue-3"*/}
                  {/*      >*/}
                  {/*        FAQ*/}
                  {/*      </Link>*/}
                  {/*    </li>*/}
                  {/*  </ul>*/}
                  {/*</div>*/}
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                <div className="col-md-3 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-6 font-weight-semibold mb-8 text-white">
                      Quick Links
                    </h4>
                    <ul className="list-unstyled">
                      <li className="mb-6">
                        <Link
                          to="/"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Home
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/benefits"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Benefits
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/student-module"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Modules
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/about"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          About
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/contact"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Contact Us
                        </Link>
                      </li>
                      {/*<li className="mb-6">*/}
                      {/*  <Link*/}
                      {/*    to="/#"*/}
                      {/*    className="font-size-5 text-lily-white gr-hover-text-blue-3"*/}
                      {/*  >*/}
                      {/*    Pricing*/}
                      {/*  </Link>*/}
                      {/*</li>*/}
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                <div className="col-lg-6 col-md-5 col-xs-8">
                  <div className="mb-10 mb-lg-0 mr-xl-12">
                    <h4 className="font-size-6 font-weight-semibold mb-8 text-white">
                      Contacts
                    </h4>
                    <p className="font-size-5 mb-0 text-lily-white">
                      1007 N Orange St. 4th Floor Suite #310, Wilmington,
                      Delaware 19801, United States
                    </p>
                    <div className="mt-5">
                      <a
                        className="font-size-5 d-block text-green mb-0"
                        href="tel:+1-302-409-0980"
                      >
                        (+1) 302 409 0980
                      </a>
                      <a
                        className="font-size-5 d-block text-green mb-0"
                        href="mailto:info@unicloud360.com"
                      >
                        info@unicloud360.com
                      </a>
                    </div>

                    <p className="font-size-5 mt-10 mb-0 text-lily-white">
                      17A, Hirana Road, Panadura 12500, Sri Lanka
                    </p>
                    <div className="mt-5">
                      <a
                        className="font-size-5 d-block text-green mb-0"
                        href="tel:+94 117 112 119"
                      >
                        +94 117 112 119
                      </a>
                      <a
                        className="font-size-5 d-block text-green mb-0"
                        href="mailto:info@ceyentra.com"
                      >
                        info@ceyentra.com
                      </a>
                    </div>

                    <div className="mt-7">
                      <Link
                        to="https://web.facebook.com/UniCloud360"
                        target="_blank"
                      >
                        <img width={36} src={fbIcon} />
                      </Link>
                      {/*<Link to="" target="_blank" className="ml-5">*/}
                      {/*  <img width={36} src={instaIcon} />*/}
                      {/*</Link>*/}
                      <Link
                        to="https://twitter.com/UniCloud360"
                        target="_blank"
                        className="ml-5"
                      >
                        <img width={36} src={twitterIcon} />
                      </Link>
                      <Link
                        to="https://www.linkedin.com/showcase/unicloud360/"
                        target="_blank"
                        className="ml-5"
                      >
                        <img width={36} src={linkedinIcon} />
                      </Link>
                    </div>
                  </div>
                </div>
                {/* End Single Widgets */}
              </div>
            </div>
          </div>
          <div className="row align-items-center pt-10 border-white border-top">
            <div className="col-lg-6 text-center text-lg-left">
              <div className="copyright">
                <p className="mb-0 font-size-3 text-bali-gray">
                  © Unicloud360 2023, All Rights Reserved
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-center text-lg-right">
              <div className="footer-right mt-5 mt-lg-0">
                <ul className="list-unstyled d-flex align-items-center justify-content-center justify-content-lg-end mb-0">
                  {/*<li>*/}
                  {/*  <Link*/}
                  {/*    to="/#"*/}
                  {/*    className="text-white gr-hover-text-blue-3 ml-7"*/}
                  {/*  >*/}
                  {/*    <i className="fab fa-twitter" />*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <Link*/}
                  {/*    to="/#"*/}
                  {/*    className="text-white gr-hover-text-blue-3 ml-7"*/}
                  {/*  >*/}
                  {/*    <i className="fab fa-facebook-f" />*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <Link*/}
                  {/*    to="/#"*/}
                  {/*    className="text-white gr-hover-text-blue-3 ml-7"*/}
                  {/*  >*/}
                  {/*    <i className="fab fa-google" />*/}
                  {/*  </Link>*/}
                  {/*</li>*/}

                  <li>
                    <Link
                      to="https://ceyentra.com/"
                      target="_blank"
                      className="text-white gr-hover-text-blue-3 ml-7"
                    >
                      <span className="font-size-3 mr-3">powered by</span>
                      <img
                        width={150}
                        height={40}
                        src={CompanyLogo}
                        className="img-fluid"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="footer-yellow-shape-img">*/}
        {/*  <img src={imgFS} alt="" />*/}
        {/*</div>*/}
      </div>
    </>
  );
};

export default Footer;
