export const menuItems = [
  {
    name: "",
    label: "Home",
    // items: [
    //   { name: "", label: "Home 01" },
    //   { name: "pages-2", label: "Home 02" },
    //   { name: "pages-3", label: "Home 03" },
    //   { name: "pages-4", label: "Home 04" },
    //   { name: "pages-5", label: "Home 05" },
    //   { name: "pages-6", label: "Home 06" },
    // ],
  },
  {
    name: "benefits",
    label: "Benefits",
  },
  {
    name: "modules",
    label: "Modules",
    items: [
      { name: "student-module", label: "Student" },
      { name: "counsellor-module", label: "Counsellor" },
      { name: "academic-module", label: "Academic" },
      { name: "lecturer-module", label: "Lecture" },
      { name: "finance-module", label: "Finance" },
      { name: "it-module", label: "IT" },
    ],
  },
  {
    name: "about",
    label: "About",
  },
  {
    name: "contact",
    label: "Contact Us",
  },
  // {
  //   name: "pricing",
  //   label: "Pricing",
  // },

  // {
  //   name: "pages",
  //   label: "pages",
  //   items: [
  //     { name: "about", label: "About Us" },
  //     {
  //       name: "careers",
  //       label: "Careers",
  //       items: [
  //         { name: "career", label: "Career" },
  //         { name: "career-details", label: "Career Details" },
  //       ],
  //     },
  //     {
  //       name: "utility",
  //       label: "Utility",
  //       items: [
  //         { name: "pricing", label: "Pricing & Plan" },
  //         { name: "contact", label: "Contact" },
  //         { name: "faq", label: "FAQ" },
  //         { name: "comingsoon", label: "Coming soon" },
  //         { name: "terms", label: "Terms & Conditions" },
  //         { name: "404", label: "404 Page" },
  //       ],
  //     },
  //     {
  //       name: "account",
  //       label: "Account",
  //       items: [
  //         { name: "signin", label: "Sign In" },
  //         { name: "signin-image", label: "Sign In Image" },
  //         { name: "signup", label: "Sign Up" },
  //         { name: "signup-image", label: "Sign Up Image" },
  //         { name: "reset-pass", label: "Reset Password" },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   name: "https://uxtheme.net/product-support/",
  //   label: "Support",
  //   isExternal: true,
  // },
];
