import React from "react";
import { GlobalProvider } from "./src/context/GlobalContext";
import Layout from "./src/components/Layout";
import ReactGA from "react-ga4";

export const onClientEntry = () => {
  // if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("G-GJGXVX6MS2");
  // }
};

export const onRouteUpdate = ({ location }) => {
  const pageTitle =
    location.pathname === "/" ? "home" : location.pathname.replace("/", "");
  // if (process.env.NODE_ENV === "production") {
  ReactGA.send({
    hitType: "pageview",
    page: location.pathname,
    title: pageTitle,
  });
  // }
};

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>{element}</GlobalProvider>
);
